<template>
  <div>
    <result-list-edit :is-edit-result-sidebar-active.sync="isEditResultSidebarActive"
      :proposedDecisionOptions="proposedDecisionOptions" :item="item" @refetch-data="refetchData" />

    <!-- Filters -->
    <results-list-filters :role-filter.sync="roleFilter" :level-filter.sync="levelFilter"
      :proposedDecisionFilter.sync="proposedDecisionFilter" :discussionFilter.sync="discussionFilter"
      :role-options="roleOptions" :level-options="levelOptions" :proposedDecisionOptions="proposedDecisionOptions"
      :discussionOptions="discussionOptions" />

    <span class="mb-0">
      <span v-if="activeCycleID" class="d-flex align-items-center justify-content-center mb-2"
        @click="showCurrentCycle">
        <b-button variant="outline-success">
          <b>Show Current Cycle</b>
        </b-button>
      </span>
    </span>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="limit" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
            <span class="ml-1" v-if="selectedCycleData">
              <b-badge variant="light-primary" pill><b>{{ formatDate(selectedCycleData.startDate) }}</b> to <b>{{
                formatDate(selectedCycleData.deadline)
                  }}</b></b-badge>
            </span>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="3">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Search..." />
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <v-select :value="selectedCycle" v-model="selectedCycle" :options="cycleOptions" placeholder="Select Cycle"
              class="w-100" :reduce="val => val.value" :clearable="false" />
          </b-col>
        </b-row>
      </div>

      <b-table ref="refResultListTable" class="position-relative" :items="fetchResults" responsive
        :fields="dynamicTableColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
        empty-text="No matching records found" :sort-desc.sync="sortOrderSync">
        <template #table-busy>
          <div class="text-center my-3">
            <b-spinner style="width: 3rem; height: 3rem;" variant="primary" label="Loading..."></b-spinner>
          </div>
        </template>

        <!-- Column: Result -->
        <template #cell(result)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.user.avatar" :text="avatarText(data.item.user.fullName)"
                :variant="`light-${resolveResultRoleVariant(data.item.user.role)}`" />
            </template>
            <b-link class="font-weight-bold d-block text-nowrap lead">{{ data.item.user.fullName }}</b-link>
            <div class="text-secondary h5 my-0 py-0">{{ data.item.user.level.name }} <small> ({{
              data.item.user.level.department
                }})</small></div>
            <small class="">{{ data.item.user.email }}</small>
          </b-media>
        </template>

        <template #cell(aggregatedSelfEval)="data">
          <span v-if="data.item.aggregatedSelfEval"><b>{{ data.item.aggregatedSelfEval.toFixed(2) }}</b></span>
          <span v-else><b-badge variant="light-secondary" pill>N/A</b-badge></span>
        </template>

        <template #cell(aggregated360Eval)="data">
          <span v-if="data.item.aggregated360Eval"><b>{{ data.item.aggregated360Eval.toFixed(2) }}</b></span>
          <span v-else><b-badge variant="light-secondary" pill>N/A</b-badge></span>
        </template>

        <template #cell(aggregatedProjectEval)="data">
          <span v-if="data.item.aggregatedProjectEval"><b>{{ data.item.aggregatedProjectEval.toFixed(2) }}</b></span>
          <span v-else><b-badge variant="light-secondary" pill>N/A</b-badge></span>
        </template>

        <!-- Column: Total Score -->
        <template #cell(aggregatedScore)="data">
          <span v-if="data"><b>{{ data.item.aggregatedScore }}</b></span>
          <span v-else><b-badge variant="light-secondary" pill>N/A</b-badge></span>
        </template>

        <!-- Column: Discussion -->
        <template #cell(discussion)="data">
          <span
            :class="data.item.discussion ? 'status-dot status-dot--active' : 'status-dot status-dot--inactive'"></span>
        </template>

        <template #cell(proposedDecision)="data">
          <span>
            <b-badge :variant="resolveResultDecisionVariant(data.item.proposedDecision)">
              {{
                data.item.proposedDecision }}
            </b-badge></span>
        </template>

        <!-- Column: Comments -->
        <template #cell(comments)="data">
          <span v-if="data.item.comments"> {{ data.item.comments }}</span>
          <span v-else><b-badge variant="light-secondary">N/A</b-badge></span>
        </template>



        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <feather-icon icon="EditIcon" class="edit-button ml-1" @click="editItem(data.item)" />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
              entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="page" :total-rows="totalResults" :per-page="limit" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BSpinner
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { ref, onUnmounted, computed, onMounted, getCurrentInstance, watch } from '@vue/composition-api'
import ResultsListFilters from './ResultsListFilters.vue'
import useResultsList from './useResultsList'
import resultStoreModule from '../resultStoreModule'
import ResultListEdit from './ResultListEdit.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    ResultsListFilters,
    ResultListEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,
    flatPickr,
  },
  setup() {
    const RESULT_APP_STORE_MODULE_NAME = 'app-result'
    // const proposedDecisionOptions = ref([])
    const levelOptions = ref([])
    const cycleOptions = ref([]) // New cycle options
    const proposedDecisionOptions = ref([])
    const allCycles = ref([])
    const activeCycleID = ref(null)
    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'User', value: 'user' },
    ]
    const discussionOptions = [
      { label: 'Yes', value: true },
      { label: 'No', value: false },
    ]

    const item = ref(null)

    const isEditResultSidebarActive = ref(false)

    const { proxy } = getCurrentInstance()

    // Register module
    if (!store.hasModule(RESULT_APP_STORE_MODULE_NAME)) store.registerModule(RESULT_APP_STORE_MODULE_NAME, resultStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(RESULT_APP_STORE_MODULE_NAME)) store.unregisterModule(RESULT_APP_STORE_MODULE_NAME)
    })

    const {
      fetchResults,
      tableColumns,
      limit,
      page,
      totalResults,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      refResultListTable,
      refetchData,

      // UI
      resolveResultRoleVariant,
      resolveResultRoleIcon,
      resolveResultStatusVariant,
      resolveResultDecisionVariant,

      // Extra Filters
      roleFilter,
      levelFilter,
      proposedDecisionFilter,
      discussionFilter,

      selectedCycle,
    } = useResultsList()

    onMounted(() => {
      // Fetch cycleOptions
      proxy.$http.get('/cycles')
        .then(response => {
          const cycles = response.data.cycles
          allCycles.value = cycles
          cycleOptions.value = cycles.map(cycle => ({ label: cycle.title, value: cycle._id }))
          selectedCycle.value = cycleOptions.value[0].value
        })
        .catch(error => console.error('Error fetching cycles:', error))


      proxy.$http.get('/cycles/isActive').then(response => {
        const isCycleActive = response.data.isActive
        if (isCycleActive) {
          proxy.$http.get('/cycles/active')
            .then(response => {
              const currentCycle = response.data
              activeCycleID.value = currentCycle._id
              selectedCycle.value = currentCycle._id
            })
            .catch(error => console.error('Error fetching active cycle:', error))
        }
      })

      proxy.$http.get('/levels')
        .then(response => {
          const levels = response.data.levels
          levelOptions.value = levels.map(level => ({ label: level.name, value: level._id }))
        })
        .catch(error => console.error('Error fetching level options:', error))

      proxy.$http.get('/results/proposedDecisionOptions')
        .then(response => {
          proposedDecisionOptions.value = Object.keys(response.data).map(key => ({
            label: response.data[key].charAt(0).toUpperCase() + response.data[key].slice(1),
            value: response.data[key]
          }));
        })
        .catch(error => console.error('Error fetching proposed decision options:', error))
    })

    const sortOrderSync = computed({
      get: () => sortOrder.value === 'desc',
      set: (newVal) => {
        sortOrder.value = newVal ? 'desc' : 'asc'
      }
    })

    const dynamicTableColumns = computed({
      get: () => {
        if (activeCycleID.value && (activeCycleID.value === selectedCycle.value)) {
          return tableColumns
        } else {
          const cols = tableColumns.filter(function (col) {
            return col.key !== 'actions';
          });
          return cols
        }
      },
    })

    const selectedCycleData = computed({
      get: () => {
        if (allCycles.value && selectedCycle.value) {
          const data = allCycles.value.filter(function (cycle) {
            return cycle._id == selectedCycle.value
          });
          return data[0]
        }
        return null
      },
    })

    return {
      // Sidebar
      isEditResultSidebarActive,

      fetchResults, // Updated to use fetchResultsWithCycle
      tableColumns,
      dynamicTableColumns,
      limit,
      page,
      totalResults,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      sortOrder,
      sortOrderSync,
      refResultListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveResultRoleVariant,
      resolveResultRoleIcon,
      resolveResultStatusVariant,
      resolveResultDecisionVariant,

      roleOptions,
      levelOptions,
      cycleOptions,
      proposedDecisionOptions,
      discussionOptions,
      selectedCycle,
      selectedCycleData,
      activeCycleID,
      allCycles,

      // Extra Filters
      roleFilter,
      levelFilter,
      proposedDecisionFilter,
      discussionFilter,
      item,
    }
  },
  methods: {
    editItem(item) {
      this.item = item
      this.isEditResultSidebarActive = true
    },
    showCurrentCycle() {
      if (this.activeCycleID) {
        this.selectedCycle = this.activeCycleID
      }
      return
    },
    formatDate(d) {
      d = new Date(d)
      const yyyy = d.getFullYear();
      let mm = d.getMonth() + 1;
      let dd = d.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      const formattedDate = dd + '.' + mm + '.' + yyyy;
      return formattedDate
    },
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.status-dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.status-dot--active {
  background-color: rgb(0, 165, 0);
}

.status-dot--inactive {
  background-color: rgba(255, 0, 0, 0.76);
}

.edit-button {
  color: $primary;
  cursor: pointer;
}

.details-button {
  color: $primary;
  cursor: pointer;
}
</style>
